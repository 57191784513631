/**
* Copyright 2016 Evoke Solutions
*
* Author: Alan Drees
*
* Purpose: Implement a basic ajax system
*/

var MAS = MAS || {};

MAS.Ajax = {};

MAS.Ajax.ajaxCall =  function(endpoint, func, data, okfunc, ctype, failfunc)
{
  if(typeof ctype === 'undefined') ctype = 'application/x-www-form-urlencoded';

  endpoint = '/wp-admin/admin-ajax.php';

  data.action = func;

  $.ajax({ url: endpoint,
    type: 'POST',
    data: data,
    cache: false,
    contentType: ctype,
    processData: true,
    timeout: 50000,
    complete: function(jqXHR, status, thrownError)
    {
      var data = JSON.parse(jqXHR.responseText);

      if(data.code === 0)
      {
        if(typeof okfunc === 'function')
        {
          okfunc(data);
        }
      }
      else
      {
        if(typeof failfunc === 'function')
        {
          failfunc(data);
        }
      }
    },
    error: function(jqXHR, status, error)
    {
      if(status === "timeout")
      {
        alert("A timeout has occured.");
      }
      else
      {
        alert(status);
      }
    }
  });
};

MAS.Ajax.articles_load_more_func = function(t, c, d) {
  var target = '.' + t;

  var count = c;

  var data = d.json;

  var new_articles = '';

  var pagedata = {
    'count'   : count,
    'page'    : data.page,
    'cat'     : data.cat,
    'exclude' : data.exclude,
    'target'  : t
  };

  for(var i = 0; i < data.posts.length; i++){
    new_articles += data.posts[i];
  }

  MAS.Ajax.setArticleData($(target).find('.es-data'), pagedata);

  $(target).append($(new_articles));

  if (data.has_next === false) {
    $('.load-more').hide();
  } else {
    $('.load-more').css('display', 'inline-block');
  }
};

MAS.Ajax.parseArticleData = function(self, map){
  for(var attr_name in map){
    map[attr_name] = parseInt($(self).attr('data-' + attr_name));
  }
  return map;
};

MAS.Ajax.setArticleData = function(self, data){
  for(var attr_name in data){
    $(self).attr('data-' + attr_name, data[attr_name]);
  }
};


$(document).ready(function() {

  var ajax = MAS.Ajax;

  var initData = ajax.parseArticleData(
    $('.article-grid').find('.es-data'),
    {'count' : -1, 'page' : -1, 'cat' : -1, 'exclude' : -1, 'post_status' : 'publish'}
  );

  // Check if there are additional articles
  ajax.ajaxCall('wp-admin', 'articles_ajax', initData, function(d) {
    if (d.json.has_next === false) {
      $('.load-more').hide();
    } else {
      $('.load-more').css('display', 'inline-block');
    }
  });


  $('.load-more').on('click', function(){
    var self = this;

    var more_count = parseInt($(this).attr('data-amt'));

    var target = $(this).attr('data-target');

    target = 'article-grid';

    var func = '';

    var data = ajax.parseArticleData(
      $('.' + target).find('.es-data'),
      {'count' : -1, 'page' : -1, 'cat' : -1, 'exclude' : -1, 'post_status' : 'publish'}
    );

    if(data.count != more_count){
      data.page = (data.count * (data.page + 1)) / more_count;
      data.count = more_count;
    }

    data.page++;

    ajax.ajaxCall('wp-admin', 'articles_ajax', data, function(d){ ajax.articles_load_more_func(target, data.count, d); });

    return false;
  });

  var populate_article_grid = function(d){
    var new_articles = '';

    var data = d.json;

    var pagedata = {
      'count'   : data.count,
      'page'    : data.page,
      'cat'     : data.cat,
      'post_status' : 'publish'
    };

    for(var i = 0; i < data.posts.length; i++){
      new_articles += data.posts[i];
    }


    $('.article-tile').remove();

    MAS.Ajax.setArticleData($('.es-data'), pagedata);

    $('.article-grid').append($(new_articles));

    if (data.has_next === false) {
      $('.load-more').hide();
    } else {
      $('.load-more').css('display', 'inline-block');
    }
  };


  if ($('.articles-resources').length > 0) {
    $('.category-select a').on('click', function(){
      var data  = MAS.Ajax.parseArticleData($('.article-grid .es-data'), {'count' : -1, 'post_status' : 'publish'});

      data.page = 0;

      data.count = 9;

      data.cat = parseInt($(this).attr('data-id'));

      MAS.Ajax.ajaxCall('wp-admin', 'articles_ajax', data, function(d){
        populate_article_grid(d);
        console.log(d);
        console.log(data);
      });

      return false;
    });
  }
});
